<template>
  <section>
    <div class="about md:bg-center bg-cover h-1/2">
      <div
        class="w-full h-96 imagebg bg-clip-padding bg-cover py-36 md:text-xs"
      >
        <div class="ml-8 md:ml-32 w-max h-min">
          <h1 class="text-rojo10 md:text-3xl py-12 uppercase text-md">
            Asociacion de usuarios
          </h1>
          <ul class="flex -mt-12">
            <li class="text-otherblue text-xl ml-1" @click="$router.push('/')">
              Inicio
            </li>
            <li class="text-graynefro text-xl ml-1">/</li>
            <li class="text-graynefro text-xl ml-1">Asociación de usuarios</li>
          </ul>
        </div>
      </div>
    </div>
  </section>

  <div class="p-5">
    <div class="lg:text-center">
      <h1 class="uppercase md:text-3xl text-otherblue font-semibold">
        ¿Qué son las&nbsp;
        <span class="text-graynefro">asociaciones de usuarios en salud?</span>
      </h1>
      <br />
      <span class=""
        >Según lo establecido por el Decreto 1757 de 1994 la alianza o
        asociación de usuarios es una agrupación de afiliados del régimen
        contributivo y subsidiado del Sistema General de Seguridad Social en
        Salud, que tienen derecho a utilizar los servicios de salud de acuerdo
        con su sistema de afiliación, que velarán por la calidad del servicio y
        defensa del usuario.</span
      >
    </div>
    <div class="p-10 sm:grid md:grid-cols-2">
      <div class="h-96 w-11/12 p-2 py-4">
        <img
          class="object-cover w-full h-full mx-auto rounded-md lg:max-w-2xl"
          src="../assets/ASOCIACION.png"
          alt="glasses photo"
        />
      </div>
      <div class="">
        <div class="">
          <div class="mt-8 text-justify">
            <p
              class="flex-auto items-center text-otherblue dark:text-gray-200 mb-5 font-semibold"
            >
              ¿COMO SE ORGANIZA?
            </p>

            <p class="flex-auto items-center text-gray-700 dark:text-gray-200">
              <span class=""
                >A través de una convocatoria a asamblea general que realizan
                las instituciones de salud, sean públicas, privadas o mixtas, a
                todos los usuarios que hayan hecho uso del servicio durante el
                último año, para su respectiva constitución.</span
              >
              <br />
              <br />
              <span class=""
                >La asociación será de carácter abierto para garantizar
                permanentemente el ingreso de nuevos usuarios. Además debe
                elaborar su propio reglamento de funcionamiento, en donde debe
                prever la organización interna como miembros directivos y
                comités de trabajo de acuerdo a las necesidades y temas a
                tratar. Las alianzas y asociaciones de usuarios elegirán sus
                representantes en asamblea general para períodos de dos
                años.</span
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <section>
    <div class="py-12">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="lg:text-center">
          <h1 class="uppercase md:text-3xl text-otherblue font-semibold">
            Funciones&nbsp;
            <span class="text-graynefro">de la asociación de usuario</span>
          </h1>
        </div>

        <div class="mt-10">
          <dl
            class="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10"
          >
            <div class="relative" v-for="(funcion, i) in funciones" :key="i">
              <p class="mt-1 ml-24 text-gray-500 text-justify">
                {{ funcion.items }}
              </p>
            </div>
          </dl>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { ref } from "@vue/reactivity";
export default {
  setup() {
    let funciones = ref([
      {
        items:
          "Información permanente a los usuarios sobre los cambios en el sistema general de seguridad social, y cambios internos de la CLÍNICA MATERNO INFANTIL CASA DEL NIÑO S.A.S., del Régimen Subsidiado relacionados con la red de servicios y el sistema de referencia y contrarreferencia.",
      },
      {
        items:
          "Realizar visita de veeduría de forma regular a las unidades funcionales. Para esta actividad los miembros de la alianza deben estar debidamente carnetizados como miembros de la asociación de usuarios y presentados ante la gerencia y los funcionarios de la unidad funcional.",
      },

      {
        items:
          "Realizar visita regular de veeduría a la oficina de atención al usuario para la revisión del proceso de referencia y atención a los usuarios.",
      },
      {
        items:
          "Participar activamente en la formulación y aplicación de estrategias que controlen la demanda innecesaria de los servicios de salud.",
      },
      {
        items:
          "Realizar capacitación a los usuarios en temas relacionados con el Sistema General de Seguridad Social en Salud (SGSSS) y derechos y deberes.",
      },
      {
        items:
          "Realizar revisión de la cartelera informativa de la oficina de atención al usuario y unidades funcionales; solicitar su actualización permanente para llevar la información a los usuarios.",
      },
      {
        items:
          "Colaborar al usuario en la formulación de peticiones, brindándole seguridad y confidencialidad a su documento.",
      },
      {
        items:
          "Colaborar al usuario en la formulación de peticiones, brindándole seguridad y confidencialidad a su documento.",
      },
      {
        items:
          "Realizar reuniones periódicas para estudiar y analizar los problemas que se presentan en la prestación de servicios y enviar estas sugerencias al Jefe de atención al usuario para buscar soluciones con la gerencia.",
      },
      {
        items: "Vigilar la aplicación de encuestas de satisfacción.",
      },
    ]);

    return { funciones };
  },
};
</script>
<style>
.imagebg {
  background-image: url("https://www.clinicacasadelnino.com/wp-content/uploads/2021/03/Portada.jpg");
}
</style>
