<template>
  <section>
    <div class="about md:bg-center bg-cover h-1/2">
      <div
        class="w-full h-96 imagebg bg-clip-padding bg-cover py-36 md:text-xs"
      >
        <div class="ml-8 md:ml-32 w-max h-min">
          <h1 class="md:text-3xl py-12 uppercase text-md">Contactenos</h1>
          <ul class="flex -mt-12">
            <li class="text-otherblue text-xl ml-1" @click="$router.push('/')">
              Inicio
            </li>
            <li class="text-graynefro text-xl ml-1">/</li>
            <li class="text-graynefro text-xl ml-1">Contáctenos</li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  <section class="bg-white md:p-8">
    <div class="sm:grid sm:grid-cols-2">
      <div class="bg-otherblue w-9/6 md:w-3/4 md:ml-36">
        <div class="flex items-center p-10">
          <h1 class="text-2xl text-white">Conoce más de Nosotros</h1>
        </div>
        <hr />
        <div class="flex items-center px-10 py-3">
          <div>
            <span
              class="text-xl font-semibold inline-block py-1 w-12 h-10 uppercase rounded-full text-white border-2"
              ><i class="fas fa-map-marker-alt"></i>
            </span>
          </div>
          <div class="px-5">
            <h4 class="text-blueGray-500 text-xl flex text-white">
              Calle 39 N° 6-15
            </h4>
            <h1 class="text-blueGray-500 md:text-sm text-xs flex text-white">
              Montería - Córdoba - Colombia.
            </h1>
          </div>
        </div>
        <div class="flex items-center px-10 py-3">
          <div>
            <span
              class="text-xl font-semibold inline-block py-1 w-12 h-10 uppercase rounded-full text-white border-2"
              ><i class="far fa-envelope"></i>
            </span>
          </div>
          <div class="px-5">
            <h4 class="text-blueGray-500 text-xl flex text-white">
              info@clinicacasadelnino.com
            </h4>
            <h1 class="text-blueGray-500 md:text-sm text-xs flex text-white">
              ¿Necesitas ayuda? Envíanos tu mensaje.
            </h1>
          </div>
        </div>
        <div class="flex items-center px-10 py-3">
          <div>
            <span
              class="text-xl font-semibold inline-block py-1 w-12 h-10 uppercase rounded-full text-white border-2"
              ><i class="fas fa-phone-alt"></i>
            </span>
          </div>
          <div class="px-5">
            <h4 class="text-blueGray-500 text-xl flex text-white">
              (4)789 0136
            </h4>
            <h1 class="text-blueGray-500 md:text-sm text-xs flex text-white">
              ¿Tienes una pregunta? Llamanos.
            </h1>
          </div>
        </div>
        <div class="flex items-center px-10 py-3">
          <div>
            <span
              class="text-xl font-semibold inline-block py-1 w-12 h-10 uppercase rounded-full text-white border-2"
              ><i class="far fa-clock"></i>
            </span>
          </div>
          <div class="px-5">
            <h4 class="text-blueGray-500 text-xl flex text-white">
              Lun – Dom 24h
            </h4>
            <h1 class="text-blueGray-500 md:text-sm text-xs flex text-white">
              Estamos en servicio.
            </h1>
          </div>
        </div>
      </div>
      <div class="sm:pr-10 md:ml-10 md:mr-16">
        <h1 class="text-2xl">¿Tienes Dudas? Contácta Con Nosotros</h1>
        <!--*********************************************************************************FORMULARIO DE CONTACTO*******************************************************************-->
        <Form
          class="p-8 md:p-0"
          @submit="contact()"
          :validation-schema="schema"
          v-slot="{ errors }"
        >
          <div class="mt-5">
            <!-- <label for="email" class="sc-bqyKva ePvcBv">Email</label> -->
            <div class="mt-5">
              <div
                class="font-bold h-6 mt-3 text-gray-600 text-xs leading-8 uppercase"
              >
                Nombre
              </div>
              <Field
                type="text"
                class="block w-full p-2 border-b-2 border-graynefro focus:ring-otherblue focus:border-transparent"
                placeholder="User-name"
                :class="{ 'is-invalid': errors.nombre }"
                v-model="datos.nombre"
                name="nombre"
              />
              <div class="text-xs text-red-600">
                {{ errors.nombre }}
              </div>
            </div>
            <div
              class="font-bold h-6 mt-3 text-gray-600 text-xs leading-8 uppercase"
            >
              Fecha
            </div>
            <Field
              type="date"
              class="block w-full p-2 border-b-2 border-graynefro focus:ring-otherblue focus:border-transparent"
              placeholder="Email"
              :class="{ 'is-invalid': errors.fecha }"
              v-model="datos.fecha"
              name="fecha"
            />
            <div class="text-xs text-red-600">
              {{ errors.fecha }}
            </div>
          </div>

          <div class="mt-5">
            <div
              class="font-bold h-6 mt-3 text-gray-600 text-xs leading-8 uppercase"
            >
              Correo electrónica
            </div>
            <Field
              type="email"
              class="block w-full p-2 border-b-2 border-graynefro focus:ring-otherblue focus:border-transparent"
              placeholder="Correo electrónico"
              :class="{ 'is-invalid': errors.email }"
              v-model="datos.email"
              name="email"
            />
            <div class="text-xs text-red-600">
              {{ errors.email }}
            </div>
          </div>
          <div class="mt-5">
            <div
              class="font-bold h-6 mt-3 text-gray-600 text-xs leading-8 uppercase"
            >
              Teléfono
            </div>
            <Field
              type="number"
              class="block w-full p-2 border-b-2 border-graynefro focus:ring-otherblue focus:border-transparent"
              placeholder="Teléfono"
              :class="{ 'is-invalid': errors.telefono }"
              v-model="datos.telefono"
              name="telefono"
            />
            <div class="text-xs text-red-600">
              {{ errors.telefono }}
            </div>
          </div>
          <div class="mt-5">
            <div
              class="font-bold h-6 mt-3 text-gray-600 text-xs leading-8 uppercase"
            >
              Información que solicita
            </div>
            <Field
              as="textarea"
              type="text"
              class="block w-full p-2 border-b-2 border-graynefro focus:ring-otherblue focus:border-transparent"
              placeholder="Información que solicita."
              v-model="datos.mensaje"
              name="mensaje"
              :class="{'is-invalid': errors.mensaje }"
            />
            <div class="text-xs text-red-600">
              {{ errors.mensaje }}
            </div>
          </div>

          <div class="mt-6 block p-5 text-sm md:font-sans text-gray-800">
            <Field
              type="checkbox"
              class="inline-block border-0"
              :class="{ 'is-invalid': errors.acceptTerms }"
              name="acceptTerms"
              value="false"
            />
            <span display="inline" class=""
              >Acepto
              <a class="" href="/s/terms" target="_blank" data-test="Link">
                <span class="underline"
                  >Política de tratamientos de datos y de la inFormación.</span
                >
              </a>
            </span>
            <div class="text-xs text-red-600">
              {{ errors.acceptTerms }}
            </div>
          </div>

          <div class="">
            <button
              type="submit"
              value="contactar"
              class="py-3 bg-otherblue text-white w-full rounded hover:bg-graynefro"
            >
              Enviar
            </button>
          </div>
        </Form>
      </div>
    </div>
  </section>
</template>

<script>
import { global } from "../Global";
import { ref } from "@vue/reactivity";
import axios from "axios";
import { useReCaptcha } from "vue-recaptcha-v3";
import { Form, Field } from "vee-validate";
import * as Yup from "yup";

export default {
  components: {
    Form,
    Field,
  },
  setup() {
    const schema = Yup.object().shape({
      nombre: Yup.string().required("Campo requerido"),
      fecha: Yup.string().required("Campo requerido"),
      email: Yup.string()
        .required("Campo requerido")
        .email("el email es invalido"),
      telefono: Yup.string().required("Campo Numérico"),
      mensaje: Yup.string().required("Campo requerido"),
      acceptTerms: Yup.string().required("campo requerido"),
    });

    let datos = ref({
      nombre: "",
      fecha: "",
      email: "",
      telefono: "",
      mensaje: "",
    });

    let submit = ref();
    let response = ref("");

    async function contact() {
      (values) => {
        alert("SUCCESS!! :-)\n\n" + JSON.stringify(values, null, 4));
      };

      let header = { header: "Access-Control-Allow-Origin" };
      console.log(datos.value);

      await axios

        .post(global.url + "Contactenos", datos.value, header)
        .then((r) => {
          response.value = r;
          console.log(r);
          // return response;

          alert("Su Solicitud fue enviada.");
          clear();
        })

        .catch((e) => console.log(e));
    }

    // RECAPTCHA
    const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();

    const recaptcha = async () => {
      await recaptchaLoaded();
      const token = await executeRecaptcha("login");
      console.log({ token });
    };

    const clear = () => {
      window.location.reload(true);
    };

    return { datos, submit, contact, recaptcha, schema, clear };
  },
};
</script>
<style>
.imagebg {
  background-image: url("https://www.clinicacasadelnino.com/wp-content/uploads/2021/03/Portada.jpg");
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
</style>
