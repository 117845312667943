<template>
  <div class="p-5">
    <div class="p-10 sm:grid md:grid-cols-2 ">
      <div class="h-150 w-11/12 p-2 py-4 ml-9">
        <img
          class="object-cover w-full h-full mx-auto rounded-md lg:max-w-2xl"
          src="@/assets/servicios/deberes/4.jpg"
          alt="glasses photo"
          width="500"
          height="250"
        />
        <!-- <iframe
          id="player"
          type="text/html"
          width="500"
          height="250"
          src="@/assets/servicios/deberes/1.jpg"
          frameborder="0"
        ></iframe> -->
      </div>
      <div class="">
        <div
          class="text-pink-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white"
        >
          <img src="../assets/LOGOCDN(2).png" />
        </div>
        <div class="">
          <h1 class="uppercase text-xl text-otherblue font-semibold">
            deberes&nbsp;
            <span class="text-graynefro">de nuestros usuarios</span>
          </h1>

          <div class="mt-4 text-justify" v-for="(deber, i) in deberes" :key="i">
            <p class="flex-auto items-center text-gray-700 dark:text-gray-200">
              <i class="far fa-check-circle text-otherblue"></i>

              <span class="mx-2">{{ deber.items }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "@vue/reactivity";
export default {
  setup() {
    let deberes = ref([
      {
        items:
          "Cumplir de manera responsable con las recomendaciones de los profesionales de salud que lo atiendan.",
      },
      {
        items:
          "Actuar de manera solidaria ante las situaciones que pongan en peligro la vida o la salud de las personas.",
      },

      {
        items:
          "Brindar la información requerida para la atención médica y contribuir con los gastos de acuerdo con su capacidad económica.",
      },
      {
        items:
          "Respetar al personal de salud y cuidar las instalaciones donde le presten dichos servicios.",
      },
      {
        items: "Cuidar su salud, la de su familia y su comunidad.",
      },
      {
        items:
          "Cumplir las normas y actuar de buena fe frente al sistema de salud.",
      },
    ]);

    return { deberes };
  },
};
</script>
