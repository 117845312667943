<template>
  <div class="p-10 w-3/12"></div>

  <div class="p-5">
    <div class="lg:text-center">
      <h1 class="uppercase md:text-3xl text-otherblue font-semibold">
        Brigada de salud&nbsp;
        <span class="text-graynefro">en Isla Fuerte</span>
      </h1>
      <br />
    </div>
    <div class="p-10 sm:grid md:grid-cols-2">
      <div class="">
        <div class="">
          <div class="mt-8 text-justify">
            <p
              class="flex-auto items-center text-otherblue dark:text-gray-200 mb-5 font-semibold"
            >
              Dando siempre lo mejor
            </p>

            <p class="flex-auto items-center text-gray-700 dark:text-gray-200">
              <span class=""
                >La promoción y prevención en la salud, es responsabilidad de
                todos y su actividad en la comunidad debe ser asumida por todos
                los profesionales tanto del área de la salud como de los
                funcionarios representantes de los diferentes organismos de
                autoridad que vigilan y administran la salud general en la
                comunidad.</span
              >
              <br />
              <br />
              <span class=""
                >La promoción de la salud centrada en el individuo, la familia y
                la comunidad, es una de las estrategias que deben fortalecerse
                para tratar de mejorar la salud y calidad de vida de los
                colombianos, por eso la CLINICA MATERNO INFANTIL CASA DEL NIÑO,
                ha fortalecido su atención en zonas rurales donde el acceso a la
                salud es muy limitado por razones de predominio
                socio-económicas. Es importante conocer a fondo y armonizar los
                principios constitucionales y los derechos humanos como
                compromiso propio de protección de la vida y conservación de la
                integridad. Además es igualmente importante tener en cuenta que
                gracias a la corrección de los factores de riego y a la
                reducción de vulnerabilidad frente a la enfermedad, podemos
                afirmar que la duración y prolongación en los periodos de vida
                sana mejoraran la esperanza de vida de las poblaciones.</span
              >
            </p>
          </div>
        </div>
      </div>
      <div class="h-96 w-11/12 p-2 py-4 ml-5">
        <Carousel>
          <Slide v-for="(image, i) in images" :key="i">
            <div class="">
              <img :src="image.img" class="" />
            </div>
          </Slide>

          <template #addons>
            <Navigation />
            <Pagination class="hidden md:flex"/>
          </template>
        </Carousel>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { Carousel, Navigation, Pagination, Slide } from "vue3-carousel";
import { ref } from "@vue/reactivity";

import "vue3-carousel/dist/carousel.css";

export default defineComponent({
  name: "Basic",
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  setup() {
    let images = ref([
        {
        img: require("@/assets/servicios/isla/7Isla.jpg"),
      },
      {
        img: require("@/assets/servicios/isla/8Isla.jpg"),
      },
      {
        img: require("@/assets/servicios/isla/9Isla.jpg"),
      },
    ]);

    return { images };
  },
});
</script>

<style>
.carousel__item {
  min-height: 200px;
  width: 100%;
  color: var(--vc-clr-white);
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__slide {
  padding: 10px;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 5px solid white;
}
</style>
