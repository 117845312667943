<template>
  <section>
    <div class="about md:bg-center bg-cover h-1/2">
      <div
        class="w-full h-96 imagebg bg-clip-padding bg-cover py-36 md:text-xs"
      >
        <div class="ml-8 md:ml-32 w-max h-min">
          <h1 class="text-rojo10 md:text-3xl py-12 uppercase text-md">
            En la Región
          </h1>
          <ul class="flex -mt-12">
            <li class="text-otherblue text-xl ml-1" @click="$router.push('/')">
              Inicio
            </li>
            <li class="text-graynefro text-xl ml-1">/</li>
            <li class="text-graynefro text-xl ml-1">En la Región</li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  <PuebloBujo />
  <IslaFuerte />
  <NuestrosObjetivos />
</template>

<script>
import PuebloBujo from "../components/enlaregion/PuebloBlujo";
import IslaFuerte from "../components/enlaregion/IslaFuerte";
import NuestrosObjetivos from "../components/enlaregion/NuestrosObjetivos";

export default {
  components: {
    PuebloBujo,
    IslaFuerte,
    NuestrosObjetivos,
  },
};
</script>

<style>
.imagebg {
  background-image: url("https://www.clinicacasadelnino.com/wp-content/uploads/2021/03/Portada.jpg");
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
</style>
