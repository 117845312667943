<template>
  <section>
    <div class="about md:bg-center bg-cover h-1/2">
      <div
        class="w-full h-96 imagebg bg-clip-padding bg-cover py-36 md:text-xs"
      >
        <div class="ml-8 md:ml-32 w-max h-min">
          <h1 class=" md:text-3xl py-12 uppercase text-md">
            Servicios Médicos
          </h1>
           <ul class="flex -mt-12">
            <li class="text-otherblue text-xl ml-1" @click="$router.push('/')">
              Inicio
            </li>
            <li class="text-graynefro text-xl ml-1">/</li>
            <li class="text-graynefro text-xl ml-1">Servicios Médicos</li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  <div class="text-center  mx-auto my-5">
    <p class="uppercase text-2xl font-semibold text-otherblue">Nuestros servicios</p>
    <h1 class="text-xl md:text-3xl font-bold mb-5 text-graynefro">
      Servicios especiales de alta calidad
    </h1>
    <h3 class="text-sm mb-5 font-light w-38 px-5">
      Desde su fundación, La Clínica Materna infantil Casa del Niño S.A.S. ha
      brindando a sus pacientes la atención médica integral, con servicios
      especiales como: de Cirugía Pediátrica, Pediatría, Obstetricia Unidad de
      cuidados intensivos neonatales y Adultos, Traumatología y ortopedia,
      laboratorio
    </h3>
  </div>
  <section class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-4 py-12">
    <div
      class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-6"
    >
    
      <div
        class="w-full bg-white rounded-lg sahdow-lg overflow-hidden flex-col justify-center items-center"
        v-for="(serv, i) in servs"
        :key="i"
      >
        <div>
          <img
            class="object-center object-cover w-full"
            :src="serv.img"
            alt="photo"
          />
        </div>
        <div class="text-center py-8 sm:py-6">
          <p class="text-xl text-gray-700 font-bold mb-2">{{ serv.name }}</p>
          <div class="text-center mb-10">
            <span
              class="inline-block w-1 h-1 rounded-full bg-graynefro ml-1"
            ></span>
            <span
              class="inline-block w-3 h-1 rounded-full bg-graynefro ml-1"
            ></span>
            <span
              class="inline-block w-40 h-1 rounded-full bg-otherblue"
            ></span>
            <span
              class="inline-block w-3 h-1 rounded-full bg-graynefro ml-1"
            ></span>
            <span
              class="inline-block w-1 h-1 rounded-full bg-graynefro ml-1"
            ></span>
          </div>
          <p class="text-base text-black text-left font-normal">
            {{ serv.description }}
          </p>
        </div>
      </div>
    </div>
  </section>
 
</template>
<script>
import { ref } from "@vue/reactivity";

export default {
  setup() {
    let servs = ref([
      {
        img: require("@/assets/servicios/neonatal.png"),
        name: "UCI Neonatal",
        description:
          "Nuestra Unidad de Cuidados Intensivos e Intermedios Neonatal (UCIN) inició atención a pacientes en 2001,lo que ha permitido adquirir una amplia experiencia y tecnología para el cuidado de los recién nacidos en todos los niveles de complejidad las 24 horas del día.",
      },
      {
        img: require("@/assets/servicios/CXPEDIATRICA.png"),
        name: "Cirugía Pediátrica",
        description:
          "Prestamos servicios quirúrgicos de mediana y alta complejidad con altos estándares de calidad,proporcionando seguridad, calidez y amabilidad a nuestros usuarios, apoyados siempre en la mejor tecnología y recurso humano existente",
      },
      {
        img: require("@/assets/servicios/medicinageneral.png"),
        name: "Medicina General",
        description:
          "Contamos con un personal con conocimientos y destrezas necesarias para diagnosticar y resolver los padecimientos de nuestros pacientes con tratamiento médico y con procedimientos sencillos.",
      },

      {
        img: require("@/assets/servicios/unidad de cuidado.png"),
        name: "Unidad de Cuidado Intermedio Pediátrico y Neonatal",
        description:
          "En este servicio realizamos una atención integral de la salud del neonato (0-30 días de vida o 44 semanas de edad corregida) que requieren monitoreo permanente de signos vitales, control de líquidos y control de peso diario.",
      },
      {
        img: require("@/assets/servicios/uciadultos.png"),
        name: "UCI Adultos",
        description:
          "La Unidad de Cuidados Intensivos e Intermedios Adulto (UCIA) está destinada a proporcionar cuidado médico y de enfermería permanente a pacientes mayores de 18 años críticamente enfermos, recuperables, donde se desarrollan actividades precisas de monitorización y manejo, cuya condición clínica pone en peligro la vida en forma inminente",
      },
      {
        img: require("@/assets/servicios/PARTO.jpg"),
        name: "Servicio para la atención del parto",
        description:
          "Este servicio cumple con estándares de calidad en la atención del pre-parto, parto y posparto que contiene valoración oportuna e integral del binomio madre-hijo, monitorización fetal y ecografía, toma de TSH Neonatal de cordón umbilical, valoración inicial del Recién Nacido por Pediatría, manejo multidisciplinario de embarazos de alto riesgo.",
      },
      {
        img: require("@/assets/servicios/NEONATAL.jpg"),
        name: "Cuidado Básico Neonatal",
        description:
          "Este servicio cuenta con 10 camas habilitadas para prestar los servicios cuidados básicos a pacientes neonatales (0-30 días de vida o 44 semanas de edad corregida), con acompañamiento permanente de médico especialista y de enfermería",
      },
      {
        img: require("@/assets/servicios/cirugiageneral.png"),
        name: "Cirugía General",
        description:
          "Realizamos cirugías de I, II, III nivel de atenciones tanto ambulatorias como hospitalarias. El servicio de cirugía de urgencia las 24 horas del día, con un manejo multidisciplinario por parte de los diferentes servicios.",
      },
      {
        img: require("@/assets/servicios/cirugiaginecologica.png"),
        name: "Cirugía Ginecológica",
        description:
          "Somos especialistas en la atención de embarazos normales o complicados por cualquier tipo de patología que son manejadas de forma médica y quirúrgica, atención del parto o cesárea y su pos operatorio.",
      },
      {
        img: require("@/assets/servicios/cirugiamaxilofacial.png"),
        name: "Cirugía Maxilofacial",
        description:
          "Nos encargamos de solucionar las patologías con estrecha relación con el aparato masticatorio, en casos como trauma, alteraciones óseas, malformaciones vasculares congénitas, reconstrucción facial, enfermedades degenerativas, entre otras.",
      },
      {
        img: require("@/assets/servicios/NEUROCIRUGIA.jpg"),
        name: "Cirugía Neurológica",
        description:
          "Con la neurocirugía intervenimos enfermedades del sistema nervioso central, periférico y vegetativo, incluyendo sus estructuras vasculares y el tratamiento quirúrgico del dolor.",
      },
      {
        img: require("@/assets/servicios/ORTOPEDICA.jpg"),
        name: "Cirugía Ortopédica",
        description:
          "Prestamos atención de urgencias las 24 horas y consulta externa, Nuestro servicio se fundamenta a la atención médico-quirúrgica del sistema óseo. Oportunidad de procedimiento quirúrgico derivado de urgencias antes de las 48 horas siguientes a la atención primaria",
      },
      {
        img: require("@/assets/servicios/cirugiaoftalmologica.png"),
        name: "Cirugía Oftalmológica",
        description:
          "Contamos con una especialidad médico-quirúrgica facultada para diagnosticar y tratar las enfermedades de los ojos, la órbita y la región periocular con el fin mejorar la salud visual de la población del departamento, teniéndose en cuenta desde el tratamiento más práctico, hasta los de mayor complejidad.",
      },
      {
        img: require("@/assets/servicios/cirugiaurologica.png"),
        name: "Cirugía urológica",
        description:
          "Este servicio tenemos la capacidad de manejar cualquier tipo de patología y procedimiento urológico (patología tenga que ver con riñón, uréter y vejiga; en el hombre próstata, testículos, pene, uretra; en la mujer vejiga y uretra)",
      },
      {
        img: require("@/assets/servicios/cirugiaotorrino.png"),
        name: "Cirugía Otorrinolaringología",
        description:
          "Nuestro equipo de trabajo se encarga del estudio tanto médico como quirúrgico, de las enfermedades del oído, las vías respiratorias superiores y parte de las inferiores, incluyendo nariz, senos paranasales, faringe y laringe.",
      },
      {
        img: require("@/assets/servicios/ciruiaplastica.png"),
        name: "Cirugía Plástica y Estética",
        description:
          "Ofrecemos una amplia cobertura para llevar a la normalidad funcional y anatómica del cuerpo. Buscamos reconstruir las alteraciones y corregir las deficiencias funcionales mediante la transformación del cuerpo humano.",
      },
      {
        img: require("@/assets/servicios/vacunacion.png"),
        name: "Vacunación",
        description:
          "Manejamos programa de administración de las vacunas para los niños nacidos en nuestra clínica.",
      },
      {
        img: require("@/assets/servicios/URGENCIAS.jpg"),
        name: "Urgencias",
        description:
          "Ofrecemos servicios de urgencias especializadas en gineco-obstetricia y pediatría las 24 horas, contamos con personal médico asistencial especializado, infraestructura física y tecnológica adecuada que nos permite brindar una respuesta ágil y eficiente a los usuarios, proporcionando en todo momento un trato cálido y humano.",
      },
      {
        img: require("@/assets/servicios/laboratorio.png"),
        name: "Laboratorio Clínico",
        description:
          "Constituye un servicio de soporte que forma parte integral de la atención, apoya al médico en la precisión del diagnóstico y pronóstico del paciente, ofreciendo procesos automatizados respaldados por los estándares más altos de calidad desde el ingreso del usuario hasta la generación del resultado, garantizando la confiabilidad y oportunidad.",
      },
      {
        img: require("@/assets/servicios/rx.png"),
        name: "Radiología e Imágenes Diagnosticas",
        description:
          "Es el servicio dedicado al diagnóstico de las enfermedades mediante el uso de métodos diagnósticos con imágenes obtenidas por medio de radiaciones ionizantes. Está bajo la responsabilidad de un médico especialista en radiología e imágenes diagnósticas. Contamos con equipo fijo y portátil.",
      },
      {
        img: require("@/assets/servicios/ambulancia.png"),
        name: "Transporte Asistencial Medicalizado",
        description:
          "Nuestra ambulancia cuenta con una dotación de alto nivel tecnológico para dar atención oportuna y adecuada a pacientes críticamente enfermos y cuya patología amerite el desplazamiento en este tipo de unidades.",
      },
      {
        img: require("@/assets/servicios/esterilizacion.png"),
        name: "Proceso de Esterilización",
        description:
          "Este servicio que recibe, acondiciona, procesa, controla y distribuye textiles, equipamiento biomédico e instrumental a todos los sectores de la Clínica, con el objetivo de proveer un insumo seguro para ser usado con el paciente.",
      },
      {
        img: require("@/assets/servicios/hospitalizacionpediatrica.png"),
        name: "Hospitalización Pediatríca",
        description:
          "Contamos con 2 sedes donde se presta el servicio de hospitalización, en total se dispone de 73 camas confortables, unipersonales y compartidas, diseñadas pensando en la comodidad y satisfacción de los usuarios y sus familias durante su estancia en la institución.",
      },
      {
        img: require("@/assets/servicios/CONSULTAEXTERNA.jpg"),
        name: "Consulta Externa",
        description:
          "Ofrecemos consulta especializada en las especialidades de ginecología, cirugía pediátrica, anestesiología, pediatría, cirugía general, psicología, ortopedia y medicina general.",
      },
      //Nuevos Servicios 
      {
        img: require("@/assets/servicios/ortopedia pediatrica.png"),
        name: "Ortopedia Pediátrica",
        description:
          "El servicio de Ortopedia pediátrica, presta sus servicios asistenciales para el diagnóstico, tratamiento y rehabilitación de niños con trauma ortopédico, atendemos todas las patologías del aparato músculo-esquelético, de la población infantil con la complejidad variable desde la más sencilla hasta la más alta.",
      },
      {
        img: require("@/assets/servicios/otologia.png"),
        name: "Otología",
        description:
          "Nuestro servicio de otología se encarga del diagnóstico clínico y quirúrgico de las enfermedades congénitas, infecciosas, traumáticas y tumorales que afectan el oído y la audición, el nervio facial y la base del cráneo, las cuales causan pérdida de audición, hipersensibilidad a sonidos ambientales “normales”.",
      },
      {
        img: require("@/assets/servicios/perinatologia.png"),
        name: "Perinatología",
        description:
          "Nuestro servicio se encarga de la atención de la mujer embarazada con factores de riesgo que pueden complicar el embarazo o que tenga complicaciones del embarazo ya sea a nivel fetal o materno.",
      },{
        img: require("@/assets/servicios/cx cabeza y cuello.png"),
        name: "cx cabeza y cuello",
        description:
          "Nuestro servicio se encarga del manejo oncológico y quirúrgico de enfermedades benignas y malignas del área de la cabeza y el cuello tales como tumores cráneo faciales, de tiroides, de parótida, de glándulas salivales y de cavidad oral (lengua y amígdalas).",
      },{
        img: require("@/assets/servicios/TORAX.jpg"),
        name: "Cirugía de Torax",
        description:
          "Nuestro servicio se encarga del estudio y tratamiento quirúrgico de las enfermedades que comprometen el pulmón, la pleura, la tráquea, el mediastino, el diafragma, la estructura ósea y los tejidos blandos de la pared torácica. ",
      },
      {
        img: require("@/assets/servicios/HEMODINAMIA.jpg"),
        name: "Hemodinamia e Intervencionismo",
        description:
          "Nuestra unidad de hemodinamia proporciona la más alta precisión y seguridad en el diagnóstico y tratamiento de enfermedades cardiovasculares, además de tecnología de vanguardia al servicio de todos nuestros pacientes. ",
      },
    ]);

    return { servs };
  },
};
</script>
<style>
.imagebg {
  background-image: url("https://www.clinicacasadelnino.com/wp-content/uploads/2021/03/Portada.jpg");
}
</style>
