<template>
  <section>
    <div class="about md:bg-center bg-cover h-1/2">
      <div
        class="w-full h-96 imagebg bg-clip-padding bg-cover py-36 md:text-xs"
      >
        <div class="ml-8 md:ml-32 w-max h-min">
          <h1 class="text-rojo10 md:text-3xl py-12 uppercase text-md">
            Nuestras Sedes
          </h1>
            <ul class="flex -mt-12">
            <li class="text-otherblue text-xl ml-1" @click="$router.push('/')">
              Inicio
            </li>
            <li class="text-graynefro text-xl ml-1">/</li>
            <li class="text-graynefro text-xl ml-1">Nuestras Sedes</li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  <!-- CARDS -->
  <section class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-4 py-12">
    <div class="grid grid-cols-1 lg:grid-cols-2 gap-6">
      <div
        class="w-full bg-white rounded-lg sahdow-lg overflow-hidden border-2 p-2 border-otherblue flex flex-col md:flex-row"
        v-for="(sede, i) in sedes"
        :key="i"
      >

      
        <div class="w-full h-80">
          <img
            class="object-center object-cover w-full h-full"
            :src="sede.img"
            alt="photo"
          />
        </div>
        <div class="w-full md:w-3/5 text-left p-6 md:p-4 space-y-2">
          <p class="text-xl text-gray-700 font-bold">Sede: {{ sede.sede }}</p>
          <p class="text-base text-gray-400 font-normal">
            {{ sede.direccion }}
          </p>
          <p class="text-base leading-relaxed text-gray-500 font-normal">
            {{ sede.telefono }}
          </p>
          <p class="text-base text-gray-400 font-normal">
            {{ sede.correo }}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { global } from "../Global";
import { ref } from "@vue/reactivity";
import { onMounted } from "vue";
import axios from "axios";
export default {
  setup() {
    const sedes = ref({});

    onMounted(async () => {
      sedes.value = await axios.get(global.url + "Sedes").then((res) => {
        return res.data;
      });

      console.log(sedes);
    });

    return { sedes };
  },
};
</script>

<style>
.imagebg {
  background-image: url("https://www.clinicacasadelnino.com/wp-content/uploads/2021/03/Portada.jpg");
}
</style>
