<template>
  <section>
    <div class="about md:bg-center bg-cover h-1/2">
      <div
        class="w-full h-96 imagebg bg-clip-padding bg-cover py-36 md:text-xs"
      >
        <div class="ml-8 md:ml-32 w-max h-min">
          <h1 class="text-rojo10 md:text-3xl py-12 uppercase text-md">
            Acerca de Nosotros
          </h1>
          <ul class="flex -mt-12">
            <li class="text-otherblue text-xl ml-1" @click="$router.push('/')">
              Inicio
            </li>
            <li class="text-graynefro text-xl ml-1">/</li>
            <li class="text-graynefro text-xl ml-1">Acerca de Nosotros</li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  <!-- This is an example component -->
  <section>
    <div
      class="px-5 md:px-10 shadow-red-600 ml-auto mr-auto mt-12 mb-20 rounded-3xl"
    >
      <div class="grid md:grid-cols-2 flex-wrap">
        <div class="px-4 mt-5 items-center">
          <img
            alt="..."
            class="w-full rounded-lg shadow-lg"
            src="../assets/servicios/fachada.png"
          />
        </div>
        <div class="px-5 mt-4 items-center">
          <h1 class="text-3xl md:max-w-xl">Nuestra Historia</h1>
          <div class="text-center -mt-2 mb-10">
            <span class="inline-block w-1 h-1 rounded-full bg-otherblue"></span>
            <span
              class="inline-block w-3 h-1 rounded-full bg-otherblue ml-1"
            ></span>
            <span
              class="inline-block w-40 h-1 rounded-full bg-graynefro"
            ></span>
            <span
              class="inline-block w-3 h-1 rounded-full bg-otherblue ml-1"
            ></span>
            <span
              class="inline-block w-1 h-1 rounded-full bg-otherblue ml-1"
            ></span>
          </div>
          <p
            class="mt-5 md:px-5 text-justify max-w-full text-sm md:text-medium"
          >
            Un grupo de médicos especialistas en las diferentes ramas de la
            pediatría y la neonatología preocupados por la inexistencia de una
            institución prestadora del servicio de cuidados intensivos a
            pacientes neonatales y pediátricos en la ciudad de Montería y sus
            alrededores, decidió realizar un estudio de factibilidad para la
            creación de una empresa que permitiera satisfacer esta necesidad en
            el departamento de Córdoba, bajo las premisas de Calidad técnico
            científicas emitidas por el entonces Ministerio de Salud. La Clínica
            nació como un proyecto de un grupo de profesionales de la salud
            quienes se reunieron en el año 2001 para crear una Sociedad que
            gestara una clínica con una proyección nacional a la que denominaron
            CASA DEL NIÑO LTDA, para brindar sus servicios a pacientes
            neonatales y pediátricos en las unidades de Cuidados intensivos,
            intermedios y básicos.
          </p>
          <p class="mt-5 md:px-5 text-justify w-full text-sm md:text-medium">
            A través de estos años hemos sido una institución que ha brindado
            amor y atención especializada en salud a los usuarios que llegan
            provenientes del departamento de Córdoba, Antioquia y otras regiones
            del país.
          </p>
          <p
            class="mt-5 md:px-5 text-justify w-full text-sm md:text-medium md:max-w-screen-sm"
          >
            En febrero de 2013 se finalizó la construcción de la nueva sede para
            el funcionamiento de la primera CLÍNICA MATERNO INFANTIL de
            Montería, diseñada con amplias, cómodas y seguras instalaciones, en
            busca de aumentar la satisfacción de nuestros usuarios y estar a la
            vanguardia del sector salud, hoy es conocida como CLINICA MATERNO
            INFANTIL CASA DEL NIÑO S.A.S
          </p>
        </div>
      </div>
    </div>
  </section>
  <Vision />

  <section>
    <div class="grid md:grid-cols-2 gap-4 flex-wrap px-8 mb-12">
      <div class="">
        <h1 class="text-otherblue text-2xl font-bold tracking-wider">
          Principios
        </h1>
        <div
          class="w-full p-3 pl-4 items-center hover:bg-gray-300 rounded-lg cursor-pointer"
        >
          <div>
            <div
              class="flex flex-col sm:flex-row justify-start items-center gap-4"
              v-for="(principio, i) in principios"
              :key="i"
            >
              <div class="bg-otherblue flex p-2 rounded-md">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 text-white"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M2.166 4.999A11.954 11.954 0 0010 1.944 11.954 11.954 0 0017.834 5c.11.65.166 1.32.166 2.001 0 5.225-3.34 9.67-8 11.317C5.34 16.67 2 12.225 2 7c0-.682.057-1.35.166-2.001zm11.541 3.708a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
              <div class="text-center sm:text-left">
                <h1 class="text-otherblue font-bold tracking-wider">
                  {{ principio.titulo }}
                </h1>
                <p class="mb-5">
                  {{ principio.texto }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="">
        <h1 class="text-otherblue text-2xl font-bold tracking-wider">
          Valores
        </h1>
        <div
          class="w-full p-3 pl-4 items-center hover:bg-gray-300 rounded-lg cursor-pointer"
        >
          <div>
            <div
              class="flex flex-col sm:flex-row justify-start items-center gap-4"
              v-for="(valor, i) in valores"
              :key="i"
            >
              <div class="bg-otherblue flex p-2 rounded-md">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 text-white"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M2.166 4.999A11.954 11.954 0 0010 1.944 11.954 11.954 0 0017.834 5c.11.65.166 1.32.166 2.001 0 5.225-3.34 9.67-8 11.317C5.34 16.67 2 12.225 2 7c0-.682.057-1.35.166-2.001zm11.541 3.708a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
              <div class="text-center sm:text-left">
                <h1 class="text-otherblue font-bold tracking-wider">
                  {{ valor.titulo }}
                </h1>
                <p class="mb-5">
                  {{ valor.texto }}
                </p>
              </div>
            </div>
            <div>
              <h1 class="text-sm"><span class="font-bold">Partes interesadas: &nbsp;</span>usuario y su familia, trabajadores, comunidad, proveedores, accionistas, entes de control, instituciones de educación superior - IES. </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<style>
.imagebg {
  background-image: url("https://www.clinicacasadelnino.com/wp-content/uploads/2021/03/Portada.jpg");
}
</style>
<script>
import { ref } from "@vue/reactivity";
import Vision from "../components/MisionVision";
export default {
  components: {
    Vision,
  },
  setup() {
    let principios = ref([
      {
        titulo: "Participación",
        texto:
          "Proporcionar espacios de participación y trabajo en equipo al personal de la Institución, que propendan por la calidad del servicio.",
      },
      {
        titulo: "Calidad",
        texto:
          "Prestar un servicio óptimo a todos los usuarios en tres aspectos básicos: Atención médica, ayudas diagnósticas, y procesos administrativos.",
      },
      {
        titulo: "Mejora Continua",
        texto:
          "Mejorar en el desempeño del sistema en términos generales que implican la eficacia y eficiencia en el cumplimiento de la política y los objetivos de la organización.",
      },
      {
        titulo: "Trabajo en equipo",
        texto:
          "Desarrollamos nuestras actividades de manera coordinada, armoniosa, responsable e integral entre todas las áreas, con el compromiso de alcanzar las metas comunes definidas por la organización, la sinergia de nuestro equipo permite alcanzar mayores beneficios que demuestran la calidad de lideres con que contamos.",
      },
      {
        titulo: "Vocación de Servicios",
        texto:
          "Implica convicción de la importancia del servicio brindado, lo cual debe proporcionar disposición y entrega para dar oportuna y esmerada atención medico asistencial.",
      },
      {
        titulo: "Transparencia",
        texto:
          "Desarrollar las actividades Institucionales con honestidad y decoro acordes a la normatividad vigente que permita el autocontrol.",
      },
      {
        titulo: "Responsabilidad Social",
        texto:
          "Estamos comprometidos con la comunidad el medio ambiente, nuestros colaboradores y sus familias.",
      },
    ]);

    let valores = ref([
      {
        titulo: "Responsabilidad",
        texto:
          "Asumimos y cumplimos con seriedad nuestros deberes y obligaciones para alcanzar los propósitos de la institución en el momento justo y con calidad deseada.",
      },
      {
        titulo: "Compromiso",
        texto:
          "El talento humano de la Clínica actúa de manera responsable con sentido de pertenencia asumiendo las acciones con esfuerzo, dedicación y empeño generando confianza y amor en nuestras actividades diaria hacia los usuarios, la institución y demás partes interesadas. ",
      },
      {
        titulo: "Disciplina",
        texto:
          "Es el valor que permite seguir un plan trazado por la clínica, ponerse objetivos y luchar hasta alcanzarlos y tener la convicción de terminar y no dejar a medias las cosas que sean importantes para la culminación de un proyecto exitoso.",
      },
      {
        titulo: "Respeto",
        texto:
          "Recocemos el valor inherente y los derechos innatos de los individuos, la sociedad y el medio ambiente.",
      },
       {
        titulo: "Lealtad",
        texto:
          "Es asumir el compromiso de fidelidad, respeto y rectitud, actuando siempre en bien a favor de la institución y con amplio sentido de pertenencia.",
      },
    ]);

    return { principios, valores };
  },
};
</script>
