<template>
  <div class="p-5">
    <hr class="border-otherblue pb-3" />
    <div class="lg:text-center">
      <div
        class="text-pink-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white"
      >
        <img src="../assets/LOGOCDN(2).png" />
      </div>
      <h1 class="uppercase text-2xl text-otherblue font-semibold">
        deberes&nbsp;
        <span class="text-graynefro">de nuestros usuarios</span>
      </h1>
    </div>
    <div class="p-10 sm:grid md:grid-cols-2">
      <div class="h-96 w-11/12 p-2 py-4">
        <img
          class="object-cover w-full h-full mx-auto rounded-md lg:max-w-2xl"
          src="https://www.clinicacasadelnino.com/wp-content/uploads/2019/10/admisiones-de-urgencia-1.jpg"
          alt="glasses photo"
        />
      </div>
      <div class="">
        <div class="">
          <div
            class="mt-4 text-justify"
            v-for="(derecho, i) in derechos"
            :key="i"
          >
            <p class="flex-auto items-center text-gray-700 dark:text-gray-200">
              <i class="far fa-check-circle text-otherblue"></i>

              <span class="mx-2">{{ derecho.items }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="">
    <div class="px-12 sm:grid md:grid-cols-2">
      <div class="">
        <div class="">
          <div
            class="mt-4 text-justify"
            v-for="(derecho, i) in derechos1"
            :key="i"
          >
            <p class="flex-auto items-center text-gray-700 dark:text-gray-200">
              <i class="far fa-check-circle text-otherblue"></i>

              <span class="mx-2">{{ derecho.items }}</span>
            </p>
          </div>
        </div>
      </div>
      <div class="h-96 w-11/12 p-2 py-4 ml-9">
        <img
          class="object-cover w-full h-full mx-auto rounded-md lg:max-w-2xl"
          src="@/assets/servicios/deberes/1.jpg"
          alt="glasses photo"
        />
      </div>
    </div>
    <div class="p-10 sm:grid md:grid-cols-2">
      <div class="h-96 w-11/12 p-2 py-4">
        <img
          class="object-cover w-full h-full mx-auto rounded-md lg:max-w-2xl"
          src="@/assets/servicios/deberes/3.jpg"
          alt="glasses photo"
        />
      </div>
      <div class="">
        <div class="">
          <div
            class="mt-4 text-justify"
            v-for="(derecho, i) in derechos2"
            :key="i"
          >
            <p class="flex-auto items-center text-gray-700 dark:text-gray-200">
              <i class="far fa-check-circle text-otherblue"></i>

              <span class="mx-2">{{ derecho.items }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "@vue/reactivity";

export default {
  setup() {
    let derechos = ref([
      {
        items:
          "Acceder a los servicios de salud; sin que se le impongan trámites administrativos adicionales a los de la ley.",
      },
      {
        items:
          "Que le autoricen y presten los servicios incluidos en el plan de beneficios de manera oportuna, si requieren un servicio que no esté incluido, también lo puede recibir.",
      },
      {
        items:
          "Recibir por escrito las razones por las que se niega el servicio de salud.",
      },
      {
        items:
          "Que, en caso de urgencia, sea atendido de manera inmediata, sin que le exijan documentos o dinero.",
      },
      {
        items: "Que atiendan con prioridad a los menores de 18 años.",
      },
      {
        items:
          "Elegir libremente el asegurador, la IPS y el profesional de la salud que quiere que lo trate dentro de la red disponible.",
      },
      {
        items:
          "Ser orientado respecto a la entidad que debe prestarle los servicios de salud requeridos.",
      },
    ]);
    let derechos1 = ref([
      {
        items:
          "Que como cotizante le paguen las licencias e incapacidades médicas.",
      },
      {
        items:
          "Tener protección especial cuando se padecen enfermedades catastróficas y de alto costo, sin el cobro de copagos.",
      },
      {
        items: "Recibir un trato digno sin discriminación alguna.",
      },
      {
        items:
          "Recibir los servicios de salud en condiciones de higiene seguridad y respeto a su intimidad.",
      },
      {
        items:
          "Recibir durante todo el proceso de su enfermedad la mejor asistencia médica.",
      },
      {
        items:
          "Recibir servicios continuos, sin interrupción y de manera integral.",
      },
      {
        items:
          "Obtener información clara y oportuna de su estado de salud, de los servicios que vaya a recibir y de los riesgos del tratamiento.",
      },
    ]);
    let derechos2 = ref([
      {
        items:
          "Recibir una segunda opinión médica si lo desea y a que su asegurador valore el concepto emitido por un profesional de la salud externo.",
      },
      {
        items: "Ser informado sobre los costos de su atención en salud.",
      },
      {
        items: "Recibir o rechazar apoyo espiritual.",
      },
      {
        items:
          "Que se Mantenga en estricta confidencialidad su información clínica.",
      },
      {
        items:
          "Que usted o la persona que lo represente acepte o rechace cualquier tratamiento y que su opinión sea tenida en cuenta y respetada.",
      },
      {
        items:
          "Ser incluido en estudio de investigación científica solo sí usted lo autoriza.",
      },
    ]);

    return { derechos, derechos1, derechos2 };
  },
};
</script>
