<template>
  <section>
    <div class="about md:bg-center bg-cover h-1/2">
      <div
        class="w-full h-96 imagebg bg-clip-padding bg-cover py-36 md:text-xs"
      >
        <div class="ml-8 md:ml-32 w-max h-min">
          <h1 class="text-rojo10 md:text-3xl py-12 uppercase text-md">
            Citas en linea
          </h1>
          <ul class="flex -mt-12">
            <li class="text-otherblue text-xl ml-1" @click="$router.push('/')">
              Inicio
            </li>
            <li class="text-graynefro text-xl ml-1">/</li>
            <li class="text-graynefro text-xl ml-1">Citas en Línea</li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  <section class="pb-20 relative block bg-blueGray-800">
    <div class="container mx-auto px-4 lg:pt-24 lg:pb-64">
      <div class="flex flex-wrap text-center justify-center">
        <div class="w-full lg:w-6/12 px-4">
          <h2 class="text-4xl font-semibold">Citas en Línea</h2>

          <p
            class="text-sm md:text-md leading-relaxed mt-4 mb-4 text-blueGray-400"
          >
            Para utilizar este medio usted debe anotar en el asunto si es
            reclamo, sugerencia o felicitación seguido de la observación que
            quiera realizar.
          </p>
          <div class="text-center mb-10">
            <span
              class="inline-block w-1 h-1 rounded-full bg-graynefro ml-1"
            ></span>
            <span
              class="inline-block w-3 h-1 rounded-full bg-graynefro ml-1"
            ></span>
            <span
              class="inline-block w-40 h-1 rounded-full bg-otherblue"
            ></span>
            <span
              class="inline-block w-3 h-1 rounded-full bg-graynefro ml-1"
            ></span>
            <span
              class="inline-block w-1 h-1 rounded-full bg-graynefro ml-1"
            ></span>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="relative block pt-24 lg:pt-0 bg-blueGray-800">
    <div class="container mx-auto px-4">
      <div class="flex flex-wrap justify-center lg:-mt-64 -mt-48">
        <div class="w-full lg:w-6/12 px-4">
          <div
            class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200"
          >
            <div class="flex-auto p-5 lg:p-10">
              <Form
                class="p-8 md:p-0"
                @submit="contact()"
                :validation-schema="schema"
                v-slot="{ errors }"
              >
                <div class="w-full mx-2 flex-1 svelte-1l8159u">
                  <div
                    class="font-bold h-6 mt-3 text-gray-600 text-xs leading-8 uppercase"
                  >
                    Nombre
                  </div>
                  <div
                    class="block w-full p-2 border-b-2 border-graynefro focus:ring-otherblue focus:border-transparent"
                  >
                    <Field
                      placeholder="Just a hint.."
                      :class="{ 'is-invalid': errors.nombre }"
                      class="p-1 px-2 appearance-none outline-none w-full text-gray-800"
                      v-model="datos.nombre"
                      name="nombre"
                    />
                  </div>
                  <div class="text-xs text-red-600">
                    {{ errors.nombre }}
                  </div>
                </div>
                <div class="flex flex-col md:flex-row">
                  <div class="w-full mx-2 flex-1 svelte-1l8159u">
                    <div
                      class="font-bold h-6 mt-3 text-gray-600 text-xs leading-8 uppercase"
                    >
                      Número de Cédula
                    </div>
                    <div
                      class="block w-full p-2 border-b-2 border-graynefro focus:ring-otherblue focus:border-transparent"
                    >
                      <Field
                        placeholder="Just a hint.."
                        :class="{ 'is-invalid': errors.cedula }"
                        class="p-1 px-2 appearance-none outline-none w-full text-gray-800"
                        v-model="datos.cedula"
                        name="cedula"
                      />
                    </div>
                    <div class="text-xs text-red-600">
                      {{ errors.cedula }}
                    </div>
                  </div>
                  <div class="w-full mx-2 flex-1 svelte-1l8159u">
                    <div
                      class="font-bold h-6 mt-3 text-gray-600 text-xs leading-8 uppercase"
                    >
                      Teléfono
                    </div>
                    <div
                      class="block w-full p-2 border-b-2 border-graynefro focus:ring-otherblue focus:border-transparent"
                    >
                      <Field
                        type="number"
                        placeholder="jhon@doe.com"
                        :class="{ 'is-invalid': errors.telefono }"
                        class="p-1 px-2 appearance-none outline-none w-full text-gray-800"
                        v-model="datos.telefono"
                        name="telefono"
                      />
                    </div>
                    <div class="text-xs text-red-600">
                      {{ errors.telefono }}
                    </div>
                  </div>
                </div>
                <div class="flex flex-col md:flex-row">
                  <div class="w-full mx-2 flex-1 svelte-1l8159u">
                    <div
                      class="font-bold h-6 mt-3 text-gray-600 text-xs leading-8 uppercase"
                    >
                      Dirección
                    </div>
                    <div
                      class="block w-full p-2 border-b-2 border-graynefro focus:ring-otherblue focus:border-transparent"
                    >
                      <Field
                        placeholder="Just a hint.."
                        :class="{ 'is-invalid': errors.direccion }"
                        class="p-1 px-2 appearance-none outline-none w-full text-gray-800"
                        v-model="datos.direccion"
                        name="direccion"
                      />
                    </div>
                    <div class="text-xs text-red-600">
                      {{ errors.direccion }}
                    </div>
                  </div>
                  <div class="w-full mx-2 flex-1 svelte-1l8159u">
                    <div
                      class="font-bold h-6 mt-3 text-gray-600 text-xs leading-8 uppercase"
                    >
                      Fecha de Nacimiento
                    </div>
                    <div
                      class="block w-full p-2 border-b-2 border-graynefro focus:ring-otherblue focus:border-transparent"
                    >
                      <Field
                        type="date"
                        placeholder="jhon@doe.com"
                        :class="{ 'is-invalid': errors.fechanat }"
                        class="p-1 px-2 appearance-none outline-none w-full text-gray-800"
                        v-model="datos.fechanat"
                        name="fechanat"
                      />
                    </div>
                    <div class="text-xs text-red-600">
                      {{ errors.fechanat }}
                    </div>
                  </div>
                </div>
                <div class="flex flex-col md:flex-row">
                  <div class="w-full mx-2 flex-1 svelte-1l8159u">
                    <div
                      class="font-bold h-6 mt-3 text-gray-600 text-xs leading-8 uppercase"
                    >
                      Fecha de atención
                    </div>
                    <div
                      class="block w-full p-2 border-b-2 border-graynefro focus:ring-otherblue focus:border-transparent"
                    >
                      <Field
                        type="date"
                        placeholder="Just a hint.."
                        :class="{ 'is-invalid': errors.fecha }"
                        class="p-1 px-2 appearance-none outline-none w-full text-gray-800"
                        v-model="datos.fecha"
                        name="fecha"
                      />
                    </div>
                    <div class="text-xs text-red-600">
                      {{ errors.fecha }}
                    </div>
                  </div>

                  <div class="w-full mx-2 flex-1 svelte-1l8159u">
                    <div
                      class="font-bold h-6 mt-3 text-gray-600 text-xs leading-8 uppercase"
                    >
                      Entidad prestadora de salud
                    </div>
                    <div
                      class="block w-full p-2 border-b-2 border-graynefro focus:ring-otherblue focus:border-transparent"
                    >
                      <Field
                        placeholder="jhon@doe.com"
                        :class="{ 'is-invalid': errors.entidad }"
                        class="p-1 px-2 appearance-none outline-none w-full text-gray-800"
                        v-model="datos.entidad"
                        name="entidad"
                      />
                    </div>
                    <div class="text-xs text-red-600">
                      {{ errors.entidad }}
                    </div>
                  </div>
                </div>
                <div class="flex flex-col md:flex-row">
                  <div class="w-full mx-2 flex-1 svelte-1l8159u">
                    <div
                      class="font-bold h-6 mt-3 text-gray-600 text-xs leading-8 uppercase"
                    >
                      Correo electrónico
                    </div>
                    <div
                      class="block w-full p-2 border-b-2 border-graynefro focus:ring-otherblue focus:border-transparent"
                    >
                      <Field
                        placeholder="Just a hint.."
                        :class="{ 'is-invalid': errors.email }"
                        class="p-1 px-2 appearance-none outline-none w-full text-gray-800"
                        v-model="datos.email"
                        name="email"
                      />
                    </div>
                    <div class="text-xs text-red-600">
                      {{ errors.email }}
                    </div>
                  </div>
                  <div class="w-full mx-2 flex-1 svelte-1l8159u">
                    <div
                      class="font-bold h-6 mt-3 text-gray-600 text-xs leading-8 uppercase"
                    >
                      Especialidad
                    </div>
                    <div
                      class="block w-full py-3 border-b-2 border-graynefro focus:ring-otherblue focus:border-transparent"
                    >
                      <select
                        as="select"
                        placeholder="jhon@doe.com"
                        class="p-1 px-2 bg-white outline-none w-full text-gray-800"
                        v-model="datos.especialidad"
                        name="asunto"
                      >
                        <option selected>Seleccione el asunto</option>
                        <option>Pediatría</option>
                        <option>Psicología</option>
                        <option>Anestesiología</option>
                        <option>Cirugía General</option>
                        <option>Cirugía Pediátrica</option>
                        <option>Ginecobstetricia</option>
                        <option>Medicina General</option>
                        <option>Ortopedia y Traumatología</option>
                        <option>Endocrinología Pediátrica</option>
                        <option>Infectología Pediátrica</option>
                        <option>Gastroenterología Pediátrica</option>
                        <option>Hemato Oncología Pediátrica</option>
                        <option>Dermatología</option>
                        <option>Neurocirugía</option>
                        <option>Ortopedia Pediátrica</option>
                        <option>Otología</option>
                        <option>Perinatología</option>
                        <option>Cirugía de Tórax</option>
                        <option>Cirugía de Cabeza y Cuello</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="w-full mx-2 flex-1 svelte-1l8159u">
                  <div
                    class="font-bold h-6 mt-3 text-gray-600 text-xs leading-8 uppercase"
                  >
                    Mensaje
                  </div>
                  <div
                    class="block w-full p-2 border-b-2 border-graynefro focus:ring-otherblue focus:border-transparent"
                  >
                    <Field
                      as="textarea"
                      placeholder="Just a hint.."
                      :class="{ 'is-invalid': errors.mensaje }"
                      class="p-1 px-2 appearance-none outline-none w-full text-gray-800"
                      v-model="datos.mensaje"
                      name="mensaje"
                    />
                  </div>
                  <div class="text-xs text-red-600">
                    {{ errors.mensaje }}
                  </div>
                </div>

                <div class="mt-6 block p-5 text-sm md:font-sans text-gray-800">
                  <Field
                    type="checkbox"
                    :class="{ 'is-invalid': errors.acceptTerms }"
                    class="inline-block border-0"
                    name="acceptTerms"
                    value="false"
                  />
                  <span display="inline" class=""
                    >Acepto
                    <a
                      class=""
                      href="/s/terms"
                      target="_blank"
                      data-test="Link"
                    >
                      <span class="underline"
                        >Política de tratamientos de datos y de la
                        información.</span
                      >
                    </a>
                  </span>
                  <div class="text-xs text-red-600">
                    {{ errors.acceptTerms }}
                  </div>
                </div>

                <div class="">
                  <button
                    type="submit"
                    value="contactar"
                    class="py-3 bg-otherblue text-white w-full rounded hover:bg-graynefro"
                    @click="recaptcha"
                  >
                    Enviar
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
      <div class="container mx-auto px-4">
        <div class="flex flex-wrap text-center justify-center"></div>
        <div class="flex flex-wrap mt-12 justify-center">
          <div class="w-full lg:w-3/12 px-4 text-center">
            <div
              class="text-lightBlue-300 p-3 w-12 h-12 shadow-lg rounded-full bg-otherblue inline-flex items-center justify-center"
            >
              <i class="fas fa-phone-alt"></i>
            </div>
            <h6 class="text-xl mt-5 font-semibold">Línea telefónica</h6>
            <p class="mt-2 mb-4 text-blueGray-400">
              (4) 7890136 ext. 1100 – 1001 – 1126
            </p>
          </div>
          <div class="w-full lg:w-3/12 px-4 text-center">
            <div
              class="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-gray-300 inline-flex items-center justify-center"
            >
              <i class="far fa-envelope"></i>
            </div>
            <h5 class="text-md mt-5 font-semibold">Correo electrónico</h5>
            <p class="mt-2 mb-4 text-blueGray-400">
              consultaexterna@clinicacasadelnino.com
            </p>
          </div>
          <div class="w-full lg:w-3/12 px-4 text-center">
            <div
              class="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-gray-300 inline-flex items-center justify-center"
            >
              <i class="fas fa-map-marker-alt"></i>
            </div>
            <h5 class="text-md mt-5 font-semibold">Dirección</h5>
            <p class="mt-2 mb-4 text-blueGray-400 text-sm">
              Carrera 6 #39-33, Sede Hospitalización
            </p>
          </div>
          <div class="w-full lg:w-3/12 px-4 text-center">
            <div
              class="text-blueGray-800 p-3 w-12 h-12 shadow-lg rounded-full bg-otherblue inline-flex items-center justify-center"
            >
              <i class="far fa-clock text-xl"></i>
            </div>
            <h5 class="text-md mt-5 font-semibold">Horario de atención</h5>
            <p class="mt-2 text-blueGray-400 text-sm px-6">
              Lunes-Viernes: 7:00 a.m - 12:00 Y 2:00 p.m - 5:00 p.m
            </p>
            <p class="mb-4 text-blueGray-400 text-sm">
              Sábados: 8:00 a.m. a 12:00 m
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { global } from "../Global";
import { ref } from "@vue/reactivity";
import axios from "axios";
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import { useReCaptcha } from "vue-recaptcha-v3";
export default {
  components: {
    Field,
    Form,
  },
  setup() {
    const schema = Yup.object().shape({
      nombre: Yup.string().required("Campo requerido"),
      cedula: Yup.string().required("Campo requerido"),
      email: Yup.string()
        .required("Campo requerido")
        .email("el email es invalido"),
      telefono: Yup.string().required("Campo requerido"),
      direccion: Yup.string().required("Campo requerido"),
      entidad: Yup.string().required("Campo requerido"),
      fecha: Yup.string().required("Campo requerido"),
      fechanat: Yup.string().required("Campo requerido"),
      mensaje: Yup.string().required("Campo requerido"),
      acceptTerms: Yup.string().required("campo requerido"),
    });

    let datos = ref({
      nombre: "",
      cedula: "",
      email: "",
      telefono: "",
      direccion: "",
      entidad: "",
      fecha: "",
      especialidad: "",
      fechanat: "",
      mensaje: "",
    });

    let submit = ref();
    let response = ref();

    async function contact() {
      (values) => {
        alert("SUCCESS!! :-)\n\n" + JSON.stringify(values, null, 4));
      };

      let header = { header: "Access-Control-Allow-Origin" };
      // console.log(datos.value);

      await axios

        .post(global.url + "CitasEnLinea", datos.value, header)
        .then((r) => {
          response.value = r;
          console.log(r);

          // return response;

          alert("Su Solicitud fue enviada.");
        })

        .catch((e) => console.log(e));
      clear();
    }

    // RECAPTCHA
    const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();

    const recaptcha = async () => {
      await recaptchaLoaded();
      const token = await executeRecaptcha("login");
      console.log({ token });
    };
    const clear = () => {
      window.location.reload(true);
    };
    return { datos, submit, contact, recaptcha, schema, clear };
  },
};
</script>
<style>
.imagebg {
  background-image: url("https://www.clinicacasadelnino.com/wp-content/uploads/2021/03/Portada.jpg");
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
</style>
