<template>
  <div class="p-10 w-3/12"></div>

  <div class="p-5">
    <div class="lg:text-center">
      <h1 class="uppercase md:text-3xl text-otherblue font-semibold">
        Clínica Materno Infantil Casa del Niño S.A.S,&nbsp;
        <span class="text-graynefro">La casa de todos.</span>
      </h1>
      <br />
    </div>
    <div class="p-10 sm:grid md:grid-cols-2">
      <div class="h-96 w-11/12 p-2 py-4">
        <Carousel>
          <Slide v-for="(image, i) in images" :key="i">
            <div class="">
              <img :src="image.img" class="" />
            </div>
          </Slide>

          <template #addons>
            <Navigation />
            <Pagination class="hidden md:flex"/>
          </template>
        </Carousel>
      </div>
      <div class="">
        <div class="">
          <div class="mt-8 text-justify">
            <p
              class="flex-auto items-center text-otherblue dark:text-gray-200 mb-5 font-semibold"
            >
              Nuestros Objetivos
            </p>

            <div class="" v-for="(item, i) in items" :key="i">
              <div class="mt-4 text-justify">
                <p
                  class="flex-auto items-center text-gray-700 dark:text-gray-200"
                >
                  <i class="far fa-check-circle text-otherblue"></i>

                  <span class="mx-2">{{ item.descripcion }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { Carousel, Navigation, Pagination, Slide } from "vue3-carousel";
import { ref } from "@vue/reactivity";

import "vue3-carousel/dist/carousel.css";

export default defineComponent({
  name: "Basic",
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  setup() {
    let images = ref([
       {
        img: require("@/assets/servicios/casa/1.jpg"),
      },
      {
        img: require("@/assets/servicios/casa/2.jpg"),
      },
      {
        img: require("@/assets/servicios/casa/3.jpg"),
      },
      {
        img: require("@/assets/servicios/casa/5.jpg"),
      },
    ]);

    let items = ref([
      {
        descripcion:
          "Fortalecer la responsabilidad social de la CLÍNICA MATERNO INFANTIL CASA DEL NIÑO S.A.S. con el fin de brindar a la comunidad servicios especializados en salud asequibles, previniendo enfermedades e identificando oportunamente problemas de salud.",
      },
      {
        descripcion:
          "Participar en la articulación de los diferentes actores del sistema a nivel departamental para la realización de brigadas de salud mas eficientes",
      },
      {
        descripcion:
          "Brindar educación a la población para que asuma una habilidad de autocuidado en la salud e higiene personal.",
      },
      {
        descripcion:
          "Realizar actividades dirigidas a la protección específica de la salud, detección temprana de enfermedades de alto riesgo y atención de enfermedades de interés en salud pública.",
      },
      {
        descripcion:
          "Crear una red de apoyo, donde se capacite al personal de la CLINICA MATERNO INFANTIL CASA DEL NIÑO S.A.S., en conjunto con la Secretaria De Salud Departamental Y Municipal, para una atención integral en las visitas realizadas a las poblaciones vulnerables.",
      },
    ]);

    return { images, items };
  },
});
</script>

<style>
.carousel__item {
  min-height: 200px;
  width: 100%;
  color: var(--vc-clr-white);
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__slide {
  padding: 10px;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 5px solid white;
}
</style>
