<template>
  <section>
    <div class="about md:bg-center bg-cover h-1/2">
      <div class="w-full h-96 imagebg bg-clip-padding bg-cover py-36 md:text-xs">
        <div class="ml-8 md:ml-32 w-max h-min">
          <h1 class="text-rojo10 md:text-3xl py-12 uppercase text-md ">Deberes y derechos</h1>
          <ul class="flex -mt-12 ">
            <li class="text-otherblue text-xl ml-1" @click="$router.push('/')" >Inicio</li>
            <li class="text-graynefro text-xl ml-1">/</li>
            <li class="text-graynefro text-xl ml-1">Derechos y Deberes</li>
          </ul>
        </div>
      </div>
    </div>
    <Deberes />
    <Derechos />
  </section>


</template>
<script>
import Deberes from '../components/Deberes'
import Derechos from '../components/Derechos'
export default {
  components:{
    Deberes,
    Derechos
  }
}
</script>
<style>
.imagebg{
  background-image:
  url('https://www.clinicacasadelnino.com/wp-content/uploads/2021/03/Portada.jpg')
}
</style>
