<template>
  <section>
    <div class="about md:bg-center bg-cover h-1/2">
      <div
        class="w-full h-96 imagebg bg-clip-padding bg-cover py-36 md:text-xs"
      >
        <div class="ml-8 md:ml-32 w-max h-min">
          <h1
            class="md:text-3xl py-12 uppercase text-md"
          >
            Plan Elite
          </h1>
           <ul class="flex -mt-12">
            <li class="text-otherblue text-xl ml-1" @click="$router.push('/')">
              Inicio
            </li>
            <li class="text-graynefro text-xl ml-1">/</li>
            <li class="text-graynefro text-xl ml-1">Plan Elite</li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  <section>
    <div
      class="md:flex items-start justify-center py-12 2xl:px-20 md:px-6 px-4"
    >
      <!--- more free and premium Tailwind CSS components at https://tailwinduikit.com/ --->

      <div class="xl:w-2/6 lg:w-2/5 w-80 md:block">
        <img
          class="w-full"
          alt="image of a girl posing"
          src="https://www.clinicacasadelnino.com/wp-content/uploads/2019/10/labo2.jpg"
        />
        <img
          class="mt-6 w-full"
          alt="image of a girl posing"
          src="https://www.clinicacasadelnino.com/wp-content/uploads/2019/10/labo2.jpg"
        />
      </div>

      <div class="xl:w-2/5 md:w-1/2 lg:ml-8 md:ml-6 md:mt-0 mt-6">
        <div class="border-b border-gray-200 pb-6">
          <p
            class="text-xs leading-none text-gray-600 dark:text-gray-300 uppercase"
          >
            queremos brindarle la mejor asesoría integral y personalizada en
            salud
          </p>
          <h1
            class="lg:text-2xl text-xl font-semibold lg:leading-6 leading-7 text-gray-800 dark:text-white mt-2"
          >
            Nuestro chequeo médico ELITE
          </h1>
        </div>
        <div
          class="py-4 border-b border-gray-200 flex items-center justify-between"
        >
          <p class="text-left leading-4 text-gray-800 dark:text-gray-300">
            El chequeo médico se debe realizar mínimo una vez al año. Este
            chequeo incluye las valoraciones y ayudas diagnósticas que buscan
            detectar enfermedades.
          </p>
        </div>
        <div
          class="justify-center lg:justify-start mt-6"
          v-for="(serv, i) in plans"
          :key="i"
          @click="desplegar(i)"
        >
          <div
            class="mx-4 px-4 py-3 bg-gray-300 text-gray-900 text-xs font-semibold rounded hover:bg-gray-400"
          >
            {{ serv.servicio }}
          </div>

          <div class="" v-for="(chequeo, c) in serv.chequeos" :key="c">
            <div v-if="showplans == i">
              <p class="ml-5 text-left text-gray-600 dark:text-gray-300">
                {{ chequeo }}
              </p>
            </div>
          </div>
        </div>
        <div class="my-5">
          <button
            @click="$router.push('Contactenos')"
            class="dark:bg-white dark:text-gray-900 dark:hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600 text-base flex items-center justify-center leading-none text-white bg-otherblue w-full py-4 hover:bg-gray-600 focus:outline-none"
          >
            <i class="fas fa-phone-alt mr-2"></i>

            Contactanos
          </button>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { ref } from "@vue/reactivity";

export default {
  setup() {
    let plans = ref([
      {
        servicio: "Imágenes diagnósticas",
        chequeos: [
          "Radiografía de tórax",
          "Ecografía abdominal",
          "Electrocardiograma",
          "Mamografía digital bilateral",
        ],
      },
      {
        servicio: "Consultas",
        chequeos: [
          "Valoración por medicina interna",
          "Valoración por nutrición y dietética",
        ],
      },
      {
        servicio: "Laboratorio",
        chequeos: [
          "Antígeno para detectar cáncer de ovario (CA125)",
          "Antígeno para detectar cáncer de próstata (PSA)",
          "Perfil lipídico",
          "Función renal",
          "Función hepática",
          "Prueba de tiroides (TSH)",
          "Coprológico",
          "Sangre oculta en heces",
          "Ácido úrico",
          "Serología VDRL",
          "Cuadro hemático",
          "Glicemia",
        ],
      },
      {
        servicio: "Intermedios",
        chequeos: [
          "Eco cardiograma doppler",
          "Prueba de esfuerzo",
          "Evaluación por cardiólogo",
          "Sesión de terapias físicas relajantes",
        ],
      },
    ]);

    const showplans = ref(0);
    function desplegar(i) {
      console.log(i);
      showplans.value = i;
    }

    return { plans, showplans, desplegar };
  },
};
</script>
<style>
.imagebg {
  background-image: url("https://www.clinicacasadelnino.com/wp-content/uploads/2021/03/Portada.jpg");
}
</style>
