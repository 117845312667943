<template>
  <div class="p-10 w-3/12"></div>

  <div class="p-5">
    <div class="lg:text-center">
      <h1 class="uppercase md:text-3xl text-otherblue font-semibold">
        Brigada de salud&nbsp;
        <span class="text-graynefro">en pueblo blujo</span>
      </h1>
      <br />
      <span class=""
        >Según lo establecido por el Decreto 1757 de 1994 la alianza o
        asociación de usuarios es una agrupación de afiliados del régimen
        contributivo y subsidiado del Sistema General de Seguridad Social en
        Salud, que tienen derecho a utilizar los servicios de salud de acuerdo
        con su sistema de afiliación, que velarán por la calidad del servicio y
        defensa del usuario.</span
      >
    </div>
    <div class="p-10 sm:grid md:grid-cols-2">
      <div class="h-96 w-11/12 p-2 py-4">
        <Carousel>
          <Slide v-for="(image, i) in images" :key="i">
            <div class="">
              <img :src="image.img" class="" />
            </div>
          </Slide>

          <template #addons>
            <Navigation />
            <Pagination class="hidden md:flex"/>
          </template>
        </Carousel>
      </div>
      <div class="">
        <div class="">
          <div class="mt-8 text-justify">
            <p
              class="flex-auto items-center text-otherblue dark:text-gray-200 mb-5 font-semibold"
            >
              PRESENTES EN CADA RINCÓN DE NUESTRA REGIÓN
            </p>

            <p class="flex-auto items-center text-gray-700 dark:text-gray-200">
              <span class=""
                >La CLÍNICA MATERNO INFANTIL CASA DEL NIÑO S.A.S., es conocedora
                de la necesidad de ofrecer a comunidades vulnerables y de pocos
                recursos la oportunidad de recibir servicios de salud
                especializados, siendo conocedores de esta realidad en el
                departamento organizamos permanentemente brigadas de
                salud..</span
              >
              <br />
              <br />
              <span class=""
                >Nuestro equipo cuenta con grandes profesionales en la salud
                como pediatras, neonatologos, radiólogos, ginecólogos,
                internistas, psicólogo, personal de enfermería, y trabajo
                social, con gran sentido social y humanístico, enfocados en
                ayudar a la población vulnerable de nuestro departamento y otros
                aledaños como Antioquia, Sucre y Bolívar.</span
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { Carousel, Navigation, Pagination, Slide } from "vue3-carousel";
import { ref } from "@vue/reactivity";

import "vue3-carousel/dist/carousel.css";

export default defineComponent({
  name: "Basic",
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  setup() {
    let images = ref([
      {
        img: require("@/assets/servicios/puebloblujo/4Pueblo.jpg"),
      },
      {
        img: require("@/assets/servicios/puebloblujo/5Pueblo.jpg"),
      },
      {
        img: require("@/assets/servicios/puebloblujo/6Pueblo.jpg"),
      },
      
    ]);

    return { images };
  },
});
</script>

<style>
.carousel__item {
  min-height: 200px;
  width: 100%;
  color: var(--vc-clr-white);
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__slide {
  padding: 10px;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 5px solid white;
}
</style>
