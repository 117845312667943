<template>

  <Carousel>
    <Slide v-for="(image , i) in images" :key="i">
      <div class="carousel__item">
           <img :src="image.img" class="" />
      </div>
    </Slide>

    <template #addons>
      <Pagination />
    </template>
  </Carousel>
</template>

<script>
import { ref } from "@vue/reactivity";
import { defineComponent } from 'vue'
import { Carousel,  Pagination, Slide } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';

export default defineComponent({
  name: 'Basic',
  components: {
    Carousel,
    Slide,
    Pagination,
   
  },
  setup(){
 let images = ref([
     
      {
        img: require("@/assets/Laborales/laboral1.jpg"),
      },
  
    ]);

    return { images };

  }
});
</script>

<style>
.carousel__item {
  min-height: 500px;
  width: 100%;
  color:  var(--vc-clr-white);
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__slide {
  padding: 10px;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 5px solid white;
}
</style>
