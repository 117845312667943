<template>
  <section>
    <div class="about md:bg-center bg-cover h-1/2">
      <div
        class="w-full h-96 imagebg bg-clip-padding bg-cover py-36 md:text-xs"
      >
        <div class="ml-8 md:ml-32 w-max h-min">
          <h1 class="text-rojo10 md:text-3xl py-12 uppercase text-md">
            Estados Financieros
          </h1>
          <ul class="flex -mt-12 ">
            <li class="text-otherblue text-xl ml-1" @click="$router.push('/')" >Inicio</li>
            <li class="text-graynefro text-xl ml-1">/</li>
            <li class="text-graynefro text-xl ml-1">Estados Financieros</li>
          </ul>
        </div>
      </div>
    </div>
  </section>

  <div class="flex flex-col justify-center items-center text-white">
    <div class="flex flex-wrap justify-center">
      <!--CARD -->
      <div class="flex p-4 ml-5 md:p-10" v-for="(doc, i) in docs" :key="i">
        <div
          class="w-full flex items-center justify-center bg-transparent"
          @click="modalActive(i)"
        >
          <div
            class="relative w-48 md:w-52 h-14 sm:h-14 md:h-16 bg-gradient-to-r from-otherblue to-yellow-600 rounded-br-full pt-4 pb-8 px-4 shadow-md hover:shadow-lg transition flex flex-col items-center"
          >
            <div
              class="absolute rounded-full bg-transparent w-16 md:w-20 md:h-20 md:p-2 z-10 -top-2 md:-top-4 -left-12 md:-left-14 transition"
            >
              <div class="rounded-full w-16 sm:w-18 md:w-20 overflow-auto">
                <img src="../assets/LOGOCDN(2).png" class="" />
              </div>
            </div>
            <div class="flex flex-col space-y-2 md:space-y-4">
              <label
                class="absolute font-bold text-gray-100 text-md text-start top-1 left-8 sm:left-10"
              >
                Estado Financiero
              </label>
              <p
                class="absolute text-gray-200 text-sm mt-1 leading-relaxed left-8 sm:left-10"
              >
                {{ doc.mes }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <section>
    <div
      v-if="showModal"
      class="fixed inset-0 w-full h-full z-50 bg-black bg-opacity-50 overflow-y-auto flex justify-center items-center"
    >
      <div class="bg-white h-4/5 w-4/5 md:justify-center ml-10">
        <iframe :src="infoModal.url" width="100%" height="100%">
          This browser does not support PDFs. Please download the PDF to view
          it: <a href="/test.pdf">Download PDF</a> </iframe
        >Informe COPPAST {{ infoModal.mes }}
      </div>
      <div class="mb-auto">
        <button
          class="bg-black mb-16 text-white rounded-b-full"
          @click="showModal = false"
        >
          <i class="far fa-times-circle w-20"></i>
        </button>
      </div>
    </div>
  </section>
</template>
<script>
import { ref } from "@vue/reactivity";
export default {
  props: {
    documentos: Array,
  },

  setup() {
    let docs = ref([
        {
        mes: "Año 2021",
        url: "https://docs.google.com/viewer?srcid=12OalJITaDU8WsYJwMoJNOcWWcU6O1qus&pid=explorer&efh=false&a=v&chrome=false&embedded=true",
      },
       {
        mes: "Año 2020",
        url: "https://docs.google.com/viewer?srcid=1mKlit1UCXLTPoPeJoxijbnREuglsYhWZ&pid=explorer&efh=false&a=v&chrome=false&embedded=true",
      },
      
   ]);

    let infoModal = ref({});

    let showModal = ref(false);
    function modalActive(value) {
      infoModal.value = docs.value[value];
      showModal.value = !showModal.value;
    //   console.log(showModal);
    }

    return { showModal, modalActive, docs, infoModal };
  },
};
</script>
<style>
.imagebg {
  background-image: url("https://www.clinicacasadelnino.com/wp-content/uploads/2021/03/Portada.jpg");
}
</style>
